import { Close } from '@mui/icons-material';
import {
  type AthleteActivationRequestRequest,
  v1AthleteActivationCreate,
} from '@on3/api';
import { Form } from '@on3/ui-lib/components/Form';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { TextArea } from '@on3/ui-lib/components/TextArea/TextArea';
import { externalApi } from '@on3/ui-lib/index';
import useAthlete from 'contexts/AthleteProvider';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';

import styles from './AthleteActivationForm.module.scss';

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
});

interface IAthleteActivationFormProps {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const AthleteActivationForm = ({
  setOpenModal,
}: IAthleteActivationFormProps) => {
  const { athlete } = useAthlete();

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [values, setValues] = useState<AthleteActivationRequestRequest>({
    personKey: athlete?.key,
    emailAddress: '',
    instagramHandle: '',
    twitterHandle: '',
    tiktokHandle: '',
    comment: '',
  });

  const onSubmit: SubmitHandler<AthleteActivationRequestRequest> = async (
    data,
  ) => {
    setErrorMessage('');
    setSuccessMessage('');

    try {
      await v1AthleteActivationCreate(externalApi, { ...data });

      setSuccessMessage(
        'Thank you, someone from On3 will be reaching out to you soon about your player profile.',
      );

      setTimeout(() => {
        setOpenModal(false);
      }, 10000);
    } catch (e: any) {
      setErrorMessage('Oops, something went wrong!');
    }
  };

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClose = useCallback(() => setOpenModal(false), [setOpenModal]);

  return (
    <Form
      className={styles.form}
      onSubmit={onSubmit}
      schema={schema}
      values={values}
    >
      {({ errors, processing }) => (
        <>
          <header className={styles.header}>
            <h2>Athlete Activation</h2>
            <button className={styles.btnClose} onClick={handleClose}>
              <Close />
            </button>
          </header>
          <p className={styles.message}>
            Thanks for your interest in claiming your player profile. Please
            provide us with as much information to help connect your account.
          </p>
          <div className={styles.fields}>
            <Input
              error={errors.emailAddress}
              label="Email Address"
              name="emailAddress"
              onChange={handleValueChange}
              value={values.emailAddress ?? ''}
            />
            <Input
              error={errors.instagramHandle}
              label="Instagram Handle"
              name="instagramHandle"
              onChange={handleValueChange}
              value={values.instagramHandle ?? ''}
            />
            <Input
              error={errors.twitterHandle}
              label="Twitter Handle"
              name="twitterHandle"
              onChange={handleValueChange}
              value={values.twitterHandle ?? ''}
            />
            <Input
              error={errors.tiktokHandle}
              label="Tiktok Handle"
              name="tiktokHandle"
              onChange={handleValueChange}
              value={values.tiktokHandle ?? ''}
            />
            <TextArea
              error={errors.comment}
              id="comment"
              label="Comment"
              multiline
              name="comment"
              onChange={handleValueChange}
              rows={4}
              value={values.comment}
            />
          </div>
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
          {successMessage && <p className={styles.success}>{successMessage}</p>}
          <div className={styles.submitContainer}>
            <button disabled={processing} type="submit">
              Submit
            </button>
          </div>
        </>
      )}
    </Form>
  );
};
