import {
  type PersonContactInfoResponse,
  type PersonInfluencerResponse,
  PersonStatusTier,
  type PlayerProfileResponse,
} from '@on3/api';
import { externalApi } from '@on3/ui-lib/index';
import { AthleteActivationModal } from 'components/UI/Modal/AthleteActivationModal';
import { useRouter } from 'next/router';
import React, {
  Context,
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'universal-cookie';

interface IAthleteContext {
  athlete: PlayerProfileResponse;
  contactInfo?: PersonContactInfoResponse | null;
  socialData?: PersonInfluencerResponse[];
  setAthlete: (value: SetStateAction<PlayerProfileResponse>) => void;
  athleteInHighSchool?: boolean;
  athleteInCollege?: boolean;
  athleteInJuco?: boolean;
  athleteInPro?: boolean;
  hasInstagram?: boolean;
  hasBaseProfile?: boolean;
  handleInstagramVerify?: () => void;
  handleOpenActivateModal?: () => void;
}

const AthleteContext: Context<IAthleteContext> = createContext(
  {} as IAthleteContext,
);

interface IAthleteResponse {
  data: {
    athleteData: PlayerProfileResponse;
    socialData?: PersonInfluencerResponse[] | null;
    contactData?: PersonContactInfoResponse | null;
  };
  children: React.ReactNode;
}

const cookies = new Cookies();

export const AthleteProvider = ({ children, data }: IAthleteResponse) => {
  const [athlete, setAthlete] = useState<PlayerProfileResponse>(
    data?.athleteData,
  );
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const handleOpenActivateModal = useCallback(
    () => setOpenActivateModal(true),
    [],
  );

  const { push } = useRouter();

  useEffect(() => {
    setAthlete(data?.athleteData);
  }, [data?.athleteData]);

  const athleteInHighSchool =
    athlete?.organizationLevel === 'HighSchool' ||
    athlete?.organizationLevel === 'PrepSchool';

  const athleteInCollege =
    athlete?.organizationLevel === 'College' ||
    athlete?.organizationLevel === 'Developmental';

  const athleteInPro = athlete?.organizationLevel === 'Professional';
  const athleteInJuco = athlete?.organizationLevel === 'JuniorCollege';

  const hasInstagram = data?.socialData?.some(
    (social) => social && social?.type === 'Instagram',
  );

  const hasBaseProfile = athlete.tier === PersonStatusTier.Base;

  const contactInfo = data?.contactData;

  const handleInstagramVerify = useCallback(async () => {
    try {
      const authUrl = `/rdb/v1/instagram/${athlete?.key}/authorize`;
      const response = await externalApi.get(authUrl);

      if (response.data) {
        const now = new Date();
        const minutes = 10;

        now.setTime(now.getTime() + minutes * 60 * 1000);
        cookies.set('claimAttempt', true, { expires: now, path: '/db' });

        push(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [athlete?.key, push]);

  const athleteContextValue = useMemo(
    () => ({
      athlete,
      contactInfo,
      setAthlete,
      athleteInHighSchool,
      athleteInJuco,
      athleteInCollege,
      athleteInPro,
      handleOpenActivateModal,
      hasInstagram,
      hasBaseProfile,
      handleInstagramVerify,
    }),
    [
      athlete,
      contactInfo,
      athleteInHighSchool,
      athleteInJuco,
      athleteInCollege,
      athleteInPro,
      handleOpenActivateModal,
      hasInstagram,
      hasBaseProfile,
      handleInstagramVerify,
    ],
  );

  return (
    <AthleteContext.Provider value={athleteContextValue}>
      {children}
      <AthleteActivationModal
        open={openActivateModal}
        setOpenModal={setOpenActivateModal}
      />
    </AthleteContext.Provider>
  );
};

export function useAthlete() {
  const context = useContext(AthleteContext);

  return context;
}

export default useAthlete;
