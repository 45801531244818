import { useSite } from '@on3/ui-lib/index';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { createElement, CSSProperties, HTMLAttributeAnchorTarget } from 'react';

interface LinkProps extends Omit<NextLinkProps, 'href'> {
  children: React.ReactNode;
  href?: string | null;
  className?: string;
  fallback?: React.ElementType;
  style?: CSSProperties;
  rel?: string;
  target?: HTMLAttributeAnchorTarget;
  useSiteColor?: boolean;
  forceReload?: boolean;
}

export const Link = ({
  children,
  href,
  className,
  fallback = 'span',
  style,
  useSiteColor = false,
  forceReload = false,
  ...props
}: LinkProps) => {
  const { currentSite } = useSite();

  const siteColor =
    currentSite?.linkColor ?? currentSite?.primaryColor ?? '#fe3b1f';

  if (useSiteColor) {
    style = {
      ...style,
      color: siteColor,
    };
  }

  // If there is no href, render a span or other fallback element
  if (!href) return createElement(fallback, { className }, children);

  // If the href is an external link, open it in a new tab
  if (href.startsWith('http') && !href.includes('on3.com')) {
    return (
      <a
        className={className}
        href={href}
        rel="noopener noreferrer"
        style={style}
        target="_blank"
        {...props}
      >
        {children}
      </a>
    );
  }

  if (forceReload) {
    return (
      <a className={className} href={href} style={style} {...props}>
        {children}
      </a>
    );
  }

  return (
    <NextLink className={className} href={href} style={style} {...props}>
      {children}
    </NextLink>
  );
};
