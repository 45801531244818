import {
  MenuResponse,
  QueryParamsType,
  SiteResponse,
  v1MenusDetail,
  v1SitesMenusDetail,
} from '@on3/api';
import { NextApiParams } from '@on3/ui-lib/types/next';
import { proxyApi } from '@on3/ui-lib/utils/api';

export const getSiteChecks = ({
  site,
  pathname,
  query,
}: {
  site?: SiteResponse;
  pathname: string;
  query: QueryParamsType;
}) => {
  const isOn3 = site?.key === 44;
  const isKsr = site?.key === 395 || site?.key === 24;
  const isHer = site?.key === 400;
  const isHighSchool = site?.key === 402;
  const isNil = site?.key === 368;
  const isPro = site?.key === 397;
  const isSoftballAmerica = site?.key === 401;
  const isTransferPortal = site?.key === 369;
  const isChannel = (site?.isChannel ?? false) && !isNil && !isTransferPortal;
  const isTeam = site?.isTeam ?? false;
  const isPlayer = pathname.startsWith('/db/[id]');
  const isCoach = pathname.includes('/db/coach');
  const isSearch = pathname.includes('/db/search');
  const isElite = pathname.startsWith('/elite');
  const isNascar = isPro && query.category === 'nascar';
  const isDb = isPlayer || isSearch || isCoach;
  const isLogin = pathname.includes('/login');
  const isRecruits = site?.key === 409;

  return {
    isOn3,
    isKsr,
    isHer,
    isHighSchool,
    isNil,
    isPro,
    isSoftballAmerica,
    isTransferPortal,
    isChannel,
    isTeam,
    isPlayer,
    isCoach,
    isSearch,
    isElite,
    isNascar,
    isDb,
    isLogin,
    isRecruits,
  };
};

export const getSiteMenus = (site: SiteResponse, ctx: NextApiParams) => {
  const on3Key = 44;
  const on3MenuKey = 90;
  const trendingMenuKey = 551442;

  const siteChecks = getSiteChecks({
    site,
    pathname: ctx.pathname ?? site.url ?? '',
    query: ctx.query ?? {},
  });

  const siteMenus: (Promise<MenuResponse[]> | null)[] = [
    v1SitesMenusDetail(proxyApi, on3Key),
    null,
  ];

  // Site specific menu for KSR, Recruits, and NIL with no secondary menu
  if (siteChecks.isKsr || siteChecks.isRecruits) {
    return [v1SitesMenusDetail(proxyApi, site.key), null];
  }

  // Trending menu for Team sites, Pro, Softball America
  if (siteChecks.isTeam || siteChecks.isPro || siteChecks.isOn3) {
    return [
      v1SitesMenusDetail(proxyApi, site.key),
      v1MenusDetail(proxyApi, trendingMenuKey),
    ];
  }

  // Secondary menu for Channel, Transfer Portal, Her, High School
  if (
    siteChecks.isChannel ||
    siteChecks.isTransferPortal ||
    siteChecks.isHer ||
    siteChecks.isHighSchool ||
    siteChecks.isNil
  ) {
    return [
      v1MenusDetail(proxyApi, on3MenuKey),
      v1SitesMenusDetail(proxyApi, site.key),
    ];
  }

  // Default to On3 with no secondary menu
  return siteMenus;
};

export enum HeaderThemes {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
  SITE = 'site',
}

export const getHeaderTheme = (
  siteChecks: ReturnType<typeof getSiteChecks>,
) => {
  if (
    siteChecks.isOn3 ||
    siteChecks.isTeam ||
    siteChecks.isPro ||
    siteChecks.isSoftballAmerica
  )
    return HeaderThemes.SITE;

  if (
    siteChecks.isChannel ||
    siteChecks.isTransferPortal ||
    siteChecks.isHer ||
    siteChecks.isHighSchool ||
    siteChecks.isNil
  )
    return HeaderThemes.SECONDARY;

  return HeaderThemes.DEFAULT;
};
