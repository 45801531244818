import { Modal } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { AthleteActivationForm } from '../Form/AthleteActivationForm';
import styles from './AthleteActivationModal.module.scss';

interface IAthleteActivationModal {
  open: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}
export const AthleteActivationModal = ({
  open,
  setOpenModal,
}: IAthleteActivationModal) => {
  return (
    <Modal className={styles.modal} open={open}>
      <AthleteActivationForm setOpenModal={setOpenModal} />
    </Modal>
  );
};
