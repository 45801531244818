import type { HeadlineResponse, SiteResponse } from '@on3/api';
import { useArticle, useAuth, useSite } from '@on3/ui-lib';
import type { IUser } from '@on3/ui-lib/api/schema/custom-contracts';
import type { IContext } from '@on3/ui-lib/api/schema/spiny-contacts';
import { PageType } from '@on3/ui-lib/types/common-props';
import { slugify } from '@on3/ui-lib/utils/slugify';
import { format } from 'date-fns-tz';
import parse from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orion: any;
  }
}
const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';

const pandoraUrl = isProd
  ? 'https://cdn-ext.spiny.ai/lib/pa/prod/D17/on3/default/pandora.min.js'
  : 'https://cdn-ext.spiny.ai/lib/pa/stage/D17/on3/default/pandora.min.js';

const getYoastWordcount = (head: string) => {
  // this is hacky. We shold get this return from the api.
  try {
    const parsedHead = parse(head);
    const yoastTag =
      Array.isArray(parsedHead) &&
      parsedHead?.find((tag) => tag?.props?.className === 'yoast-schema-graph')
        ?.props?.dangerouslySetInnerHTML?.__html;

    if (!yoastTag) {
      return null;
    }

    // Find the JSON string within the script tag
    const jsonStartIndex = yoastTag.indexOf('{');
    const jsonEndIndex = yoastTag.lastIndexOf('}');
    const jsonString = yoastTag.substring(jsonStartIndex, jsonEndIndex + 1);

    // Parse the JSON string and access the wordCount property
    const json = JSON.parse(jsonString);
    const wordCount = json['@graph'][0].wordCount;

    return wordCount;
  } catch (error) {
    try {
      const match = head.match(/"wordCount":(\d+)/);

      if (match) {
        return Number(match[1]);
      }
    } catch (err) {
      return null;
    }

    return null;
  }
};

const spinyPageview = ({
  user,
  article,
  pageType,
  pagePath,
  currentSite,
}: {
  user?: IUser;
  article?: HeadlineResponse | null;
  pageType: PageType;
  pagePath?: string;
  currentSite?: SiteResponse;
}) => {
  const { a: userAlias, uid, st: userStatus } = user || {};
  const subType = userAlias ? userStatus : 'guest';
  const {
    isPremium,
    categories,
    tags,
    key,
    author,
    postDateGMT,
    modifiedDateGMT,
    primaryCategory,
  } = article || {};
  const formattedCategories =
    categories && categories.length > 0
      ? categories?.map((category) => {
          return { name: `${category?.name}`, id: `${category?.key}` };
        })
      : null;
  const formattedTags =
    tags && tags?.length > 0
      ? tags?.map((tag) => {
          return { name: `${tag?.name}`, id: `${tag?.key}` };
        })
      : null;
  const spinyPrimaryCategory = primaryCategory && {
    name: `${primaryCategory?.name}`,
    id: `${primaryCategory?.key}`,
  };

  const wordCount = article?.head ? getYoastWordcount(article?.head) : null;

  const formatString = "yyyy-MM-dd'T'HH:mm:ssxxx";

  const formattedModifiedDate = modifiedDateGMT
    ? format(new Date(modifiedDateGMT), formatString, { timeZone: 'UTC' })
    : null;

  const formattedPostDate = postDateGMT
    ? format(new Date(postDateGMT), formatString, { timeZone: 'UTC' })
    : null;

  const network = pagePath?.includes('/') ? pagePath.split('/')[1] : 'on3';

  const context: IContext = {
    channel: pagePath,
    network: {
      domain: network,
    },
    author: {
      authorId: author?.key || null,
      authorName: author?.name || null,
    },
    page: {
      pageType: pageType || 'other',
      primaryCategory: spinyPrimaryCategory,
      categories: formattedCategories,
      keywords: formattedTags,
      premium: isPremium ? 1 : 0,
      contentId: `${key}`,
      dateModified: formattedModifiedDate,
      datePosted: formattedPostDate,
      wordCount,
    },
    site: {
      id: `${currentSite?.key}`,
      type: `${currentSite?.type}`,
    },
    user: {
      userId: uid ? parseInt(uid) : null,
      userStatus: subType,
    },
  };

  window.orion.queue = window?.orion?.queue || [];

  !isProd && console.debug('spiny pageview');
  window?.orion?.queue?.push(() => {
    window.orion.init({
      context,
    });
  });
};

export const Spiny = () => {
  const { asPath: path } = useRouter();
  const { article } = useArticle();
  const { user } = useAuth();
  const { pageType, currentSite } = useSite();

  const getSpinyChannel = useCallback(() => {
    const isNetwork = currentSite?.key === 44;
    const isNil = currentSite?.key === 368;
    const isTP = currentSite?.key === 369;
    const isKsrPlus = currentSite?.key === 395;
    const isOn3Pro = currentSite?.key === 397;
    const isHer = currentSite?.key === 400;
    const isSoftballAmerica = currentSite?.key === 401;
    const isHighSchool = currentSite?.key === 402;
    const isChannel = currentSite?.isChannel && currentSite?.organizationKey;
    const isFansite = currentSite?.isTeam && currentSite?.organizationKey;

    if (isOn3Pro) {
      return 'network/pro';
    } else if (isNetwork) {
      return 'network/on3';
    } else if (isNil) {
      return 'network/nil';
    } else if (isHer) {
      return 'network/her';
    } else if (isHighSchool) {
      return 'network/high-school';
    } else if (isSoftballAmerica) {
      return 'team/softball-america';
    } else if (isTP) {
      return 'network/transfer-portal';
    } else if (isKsrPlus) {
      return 'team/ksr-plus';
    } else if (isChannel) {
      const orgName =
        currentSite?.organization?.knownAs ?? currentSite?.organization?.name;
      const displayOrg = orgName ? slugify(orgName) : 'no-channel';

      return `channel/${displayOrg}`;
    } else if (isFansite) {
      const siteName = currentSite?.siteName
        ? slugify(currentSite?.siteName)
        : 'no-site';

      return `team/${siteName}`;
    }

    return 'network/on3';
  }, [currentSite]);

  const pagePath = getSpinyChannel();

  useEffect(() => {
    spinyPageview({ user, article, pageType, pagePath, currentSite });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const orionScript = useCallback(() => {
    return {
      __html: `(function(s,p,i,n,y){s[i]=s[i]||{queue:[]};n.forEach(function(m){s[i][m] =s[i][m]||function () {var a = arguments;s[i].queue.push(function () {s[i][m].apply(s[i], a);});};});if(y){var e=p.createElement("script");e.type="text/javascript";e.async=true;e.src=y;var f=p.getElementsByTagName("script")[0];if(f){f.parentNode.insertBefore(e,f)}else{var d=p.getElementsByTagName("head")[0];if(d){d.appendChild(e)}}}})(window, document, "orion", ["init","registerElement","setConfig", "setContext","newPageView"], null);`,
    };
  }, []);

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window.spiny = window.spiny || {};
          window.spiny.bidroll = window.spiny.bidroll || {};
          window.spiny.bidroll.enabled = false;
        `,
        }}
        strategy="beforeInteractive"
      />
      <Script
        dangerouslySetInnerHTML={orionScript()}
        strategy="beforeInteractive"
      />
      <Script src={pandoraUrl} strategy="afterInteractive" />
      <Head>
        <link
          crossOrigin="use-credentials"
          href="https://cdn-ext.spiny.ai"
          rel="preconnect"
        />
        <link
          crossOrigin="use-credentials"
          href="https://cdn-ext.spiny.ai"
          rel="dns-prefetch"
        />
      </Head>
    </>
  );
};
