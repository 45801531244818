import React, { FC, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import styles from './TextArea.module.scss';

interface TextAreaProps {
  id: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: FieldError | string;
  placeholder?: string;
  value?: string | null;
  suffix?: React.ReactNode | string;
  helperText?: string;
  [key: string]: any;
}

const TextArea: FC<TextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(
  (
    {
      id,
      label,
      error,
      placeholder = '',
      value,
      helperText,
      onChange,
      suffix,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={styles.base} data-ui="textarea">
        <label data-ui="textarea-label" htmlFor={id}>
          {label}
        </label>
        <div className={styles.wrapper}>
          <textarea
            id={id}
            onChange={onChange}
            placeholder={placeholder}
            ref={ref}
            value={value || ''}
            {...props}
          />
          {suffix && <div className={styles.suffix}>{suffix}</div>}
        </div>
        {helperText && <p className={styles.helper}>{helperText}</p>}
        {error && (
          <p className={styles.error}>
            {typeof error === 'object' ? error.message : error}
          </p>
        )}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';

export { TextArea };
